
import './App.css';
import { NavMenu } from '@shopify/app-bridge-react';
import { Routes, Route} from "react-router-dom";
import Home from './Components/Home';
import Live from './Components/Live';
import Templates from './Components/Templates';


function App() {
  return (
    <>
      <NavMenu>
        <a href="/" rel="home">
          Home
        </a>
        <a href="/templates">Templates</a>
        <a href="/live">Live</a>
      </NavMenu>
      <Routes>           
          <Route path="/" element={<Home />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/live" element={<Live />} />         
      </Routes>
    </>
  );
}

export default App;
