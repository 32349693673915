
const WHEPClient = async function (resource,remoteVideoElement,iceServers){
    
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

    window.pc = new RTCPeerConnection({
        iceServers: iceServers,
        bundlePolicy: 'max-bundle'
    });
    
    const candidatesPromise = new Promise((resolve) => {
        window.pc.addEventListener('icegatheringstatechange', (ev) => {
        let connection = ev.target;

        switch (connection.iceGatheringState) {
            case "complete":
            resolve()
            break;
        }
        })
    })

    const remoteTracksPromise = new Promise(resolve => {
        let tracks = [];
        window.pc.ontrack = event => {
        tracks.push(event.track);
        console.debug(event)
        if (tracks.length >= 2) {
            // remote video & audio are ready
            resolve(tracks);
        }
        };
    });
    const offer = await fetch(resource, {method: "POST"})
    await window.pc.setRemoteDescription(new RTCSessionDescription({type: "offer", sdp: await offer.text()}))
    const answer = await window.pc.createAnswer()
    await window.pc.setLocalDescription(answer)
    await candidatesPromise
    let sessionUrl = new URL(resource)
    sessionUrl.pathname = offer.headers.get('location')
    await fetch(sessionUrl.href, {method: "PATCH", body: answer.sdp})
    const remoteTracks = await remoteTracksPromise;
    const remoteStream = new MediaStream();
    remoteStream.addTrack(remoteTracks[0]);
    remoteStream.addTrack(remoteTracks[1]);
    remoteVideoElement.srcObject = remoteStream;
}

export default WHEPClient;