

import {Page, Layout,Card} from '@shopify/polaris';
import QRCode from 'qrcode.react';


function Live() {
    

    return (
        <Page title="Welcome to JsRates">           
            <Layout>
                <Layout.Section>
                    <Card>                        
                        <QRCode value="https://whip-player.pages.dev/broadcast" />
                    </Card>
                </Layout.Section>   

            </Layout>
        </Page>
    );
}

export default Live;