

import {Page,Grid,Box, Text,BlockStack, Card, Link, Button} from '@shopify/polaris';
import {QRCodeSVG} from 'qrcode.react';
import {useRef,useEffect} from "react";
//import { useAppBridge } from '@shopify/app-bridge-react';
import { selected } from '../assets/js/products';

import WHEPClient from '../assets/js/WHEPClient';

const share = async ()=>{
    try {
        const shareData = {
          text: 'Share broadcast url',
          url: 'https://whep-player-test.pages.dev',
          title:"Share broadcast url"
        };
        await navigator.share(shareData);
      } catch (err) {
        console.log('Share error', err);
      }
      
}

const getIceServers = async () => {
    const turn_url = "https://video-project-test.pages.dev/get-ice-servers";
    const iceServersJSON = await fetch(turn_url);
    const iceServers = await iceServersJSON.json();
    return iceServers;
}

function Home() {
    //const shopify = useAppBridge();

    const videoRef = useRef(null);
    useEffect(()=>{
        getIceServers()
        .then(response =>{
            const {iceServers,videoUrl} = response;
            WHEPClient(videoUrl,videoRef.current,iceServers);
        } );
        
    },[videoRef]);

    const handleClick = ()=>{
        share();
    }

    const addProducts = async ()=>{
        // const selected = await shopify.resourcePicker({
        //     type: 'product',
        //     multiple: true,
        //   });
        console.log("selected",selected)
    }


    return (
        <Page fullWidth title="Create Live video" >           
            <Grid >
                
                <Grid.Cell columnSpan={{xs: 12, sm: 6, md: 2, lg: 4, xl: 2}}>

                    <BlockStack gap="400">    
                        <Text as="h3" variant="headingMd">Use QR code or link to broadcast</Text>
                       
                            <BlockStack gap="400" align="center">
                                <Card>
                                    <BlockStack gap="400">  
                                        <Link monochrome url="https://whep-player-test.pages.dev" external>Broadcast live</Link>
                                        <Button onClick={handleClick} >Share broadcast url</Button>
                                    </BlockStack> 
                                </Card>
                                <Card>
                                    <BlockStack gap="400"> 
                                        <Text as="h3" variant="headingMd">QR code</Text> 
                                        <Box>
                                            <QRCodeSVG width="200px" height="200px"  value="https://whep-player-test.pages.dev" />
                                        </Box> 
                                    </BlockStack>
                                </Card>
                                <Card>
                                    <BlockStack  gap="400">
                                        <Text as="h3" variant="headingMd">Choose products to display in live video</Text> 
                                        <Button onClick={addProducts} >Add products</Button>
                                    </BlockStack>
                                </Card>
                            </BlockStack> 
                       
                                            
                    </BlockStack>
                    
                    
                </Grid.Cell>
                <Grid.Cell columnSpan={{xs: 12, sm: 6, md: 4, lg: 8, xl: 10}}>                  
                    <BlockStack gap="400">    
                        <Text as="h3" variant="headingMd">Live video preview</Text> 
                        <Card padding={0}>                          
                            <video ref={videoRef} width="100%" style={{height:"calc(100vh - 11rem)",background:"#000",marginBottom:"-4px"}} id="remote-video" controls autoPlay muted></video>               
                        </Card>
                    </BlockStack>
                </Grid.Cell>
            
          

            </Grid>
        </Page>
    );
}

export default Home;