export const selected = [
    {
        "availablePublicationCount": 7,
        "createdAt": "2022-07-08T10:06:46Z",
        "descriptionHtml": "<!-- DESC --><p><strong>Style:</strong> Chic,Vintage<br><strong>Material:</strong> Acrylic<br><strong>Pattern Type:</strong> Contrast Paneled,Plain,Colorblock<br><strong>Occasion:</strong> Daily<br><strong>Package Include:</strong> 1Pair Sunglasses<br></p><!-- SPEC -->",
        "handle": "eddcdc0d-7344-4b8d-831a-5a620b63b25e",
        "hasOnlyDefaultVariant": false,
        "id": "gid://shopify/Product/7184100393140",
        "images": [
            {
                "id": "gid://shopify/ProductImage/33992973516980",
                "altText": "1Pair Cat Eye Frame Vintage Sunglasses",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/f6fa43d5-1c8f-4782-a5a5-7f0e6064d58a-02118-pc.jpg?v=1657274806"
            },
            {
                "id": "gid://shopify/ProductImage/33992973549748",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/f6fa43d5-1c8f-4782-a5a5-7f0e6064d58a-02118-pc-sec.jpg?v=1657274806"
            },
            {
                "id": "gid://shopify/ProductImage/33992973582516",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/f6fa43d5-1c8f-4782-a5a5-7f0e6064d58a-02118-0.jpg?v=1657274806"
            },
            {
                "id": "gid://shopify/ProductImage/33992973615284",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/f6fa43d5-1c8f-4782-a5a5-7f0e6064d58a-02118-1.jpg?v=1657274806"
            },
            {
                "id": "gid://shopify/ProductImage/33992973648052",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/1i6d5J559Z5G0O6d3j903H8L9H-02118.jpg?v=1657274806"
            }
        ],
        "options": [
            {
                "id": "gid://shopify/ProductOption/9196957991092",
                "name": "Color",
                "position": 1,
                "values": [
                    "white"
                ]
            },
            {
                "id": "gid://shopify/ProductOption/9196958023860",
                "name": "Size",
                "position": 2,
                "values": [
                    "One Size"
                ]
            }
        ],
        "productType": "",
        "publishedAt": "2022-07-08T10:06:39Z",
        "tags": [
            "Accesorios",
            "Accessoires",
            "Accessories",
            "Acessórios",
            "Acryl",
            "Acrylic",
            "Acrylique",
            "Acrílico",
            "Akryl",
            "Akryyli",
            "Almindeligt",
            "Andstæða Paneled",
            "Aukahlutir",
            "Aurinkolasit",
            "Avião",
            "blanc",
            "blanco",
            "Bloc de couleur",
            "Bloque de color",
            "branco",
            "Chic",
            "Chique",
            "Color_white",
            "Colorblock",
            "Contrast Paneled",
            "Contraste Panneaux",
            "Daglega",
            "Daglig",
            "Daglige",
            "Dagligen",
            "Daily",
            "Des lunettes de soleil",
            "Diariamente",
            "Diario",
            "Ebene",
            "Elegante",
            "Enkel",
            "Farbblock",
            "Fargeblokk",
            "Flottur",
            "Färgblock",
            "Gafas de sol",
            "hvid",
            "hvit",
            "hvítt",
            "Jahrgang",
            "Kontrast Panels",
            "Kontrastipaneeli",
            "Kontrastpanel",
            "Lisätarvikkeet",
            "Litablokk",
            "Llanura",
            "Létt",
            "Material_Acrylic",
            "Neu bei Sonnenbrillen",
            "New In Sunglasses",
            "New_Arrivals",
            "Nouveau dans les lunettes de soleil",
            "Novo em óculos de sol",
            "Nuevo en gafas de sol",
            "Nyt i solbriller",
            "Nytt i solbriller",
            "Nytt i solglasögon",
            "Nýtt í sólgleraugu",
            "Occasion_Daily",
            "Oculos de sol",
            "Package Include_1Pair Sunglasses",
            "Painel de contraste",
            "Paneles de contraste",
            "Pattern Type_Colorblock",
            "Pattern Type_Contrast Paneled",
            "Pattern Type_Plain",
            "Plain",
            "Plaine",
            "Päivittäin",
            "Quotidien",
            "Schick",
            "Size_One Size",
            "Solbriller",
            "Solglasögon",
            "Sonnenbrille",
            "Style_Chic",
            "Style_Vintage",
            "Sunglasses",
            "Sólgleraugu",
            "Tavallinen",
            "tilbehør",
            "Tillbehör",
            "Tyylikäs",
            "Täglich",
            "Uusi aurinkolasit",
            "valkoinen",
            "Vanlig",
            "Vendimia",
            "Vintage",
            "vit",
            "Väripalikka",
            "Weiß",
            "white",
            "Zubehör",
            "Årgång",
            "Élégant"
        ],
        "templateSuffix": "",
        "title": "1Pair Cat Eye Frame Vintage Sunglasses",
        "totalInventory": 95,
        "totalVariants": 1,
        "tracksInventory": true,
        "updatedAt": "2024-05-22T23:28:59Z",
        "variants": [
            {
                "availableForSale": true,
                "barcode": "",
                "compareAtPrice": "22.00",
                "createdAt": "2022-07-08T10:06:47Z",
                "displayName": "1Pair Cat Eye Frame Vintage Sunglasses - white / One Size",
                "fulfillmentService": {
                    "id": "gid://shopify/FulfillmentService/58737033396?id=true",
                    "inventoryManagement": false,
                    "productBased": true,
                    "serviceName": "New Fulfillment Service",
                    "type": "THIRD_PARTY"
                },
                "id": "gid://shopify/ProductVariant/41640814969012",
                "image": {
                    "id": "gid://shopify/ProductImage/33992973648052",
                    "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/1i6d5J559Z5G0O6d3j903H8L9H-02118.jpg?v=1657274806"
                },
                "inventoryItem": {
                    "__typename": "InventoryItem",
                    "id": "gid://shopify/InventoryItem/43737918013620"
                },
                "inventoryManagement": "SHOPIFY",
                "inventoryPolicy": "DENY",
                "inventoryQuantity": 95,
                "position": 1,
                "price": "22.00",
                "product": {
                    "__typename": "Product",
                    "id": "gid://shopify/Product/7184100393140"
                },
                "requiresShipping": false,
                "selectedOptions": [
                    {
                        "__typename": "SelectedOption",
                        "value": "white"
                    },
                    {
                        "__typename": "SelectedOption",
                        "value": "One Size"
                    }
                ],
                "sku": "YF129-w-OS-ichic",
                "taxable": true,
                "title": "white / One Size",
                "updatedAt": "2024-03-28T04:32:40Z",
                "weight": 1.1,
                "weightUnit": "GRAMS"
            }
        ],
        "vendor": "ABC Fashion",
        "status": "ACTIVE"
    },
    {
        "availablePublicationCount": 7,
        "createdAt": "2022-07-08T09:44:51Z",
        "descriptionHtml": "<!-- DESC --><p><strong>Style:</strong> Chic<br><strong>Material:</strong> Acrylic<br><strong>Pattern Type:</strong> Ombre<br><strong>Occasion:</strong> Daily<br><strong>Package Include:</strong> 1Pair Sunglasses<br></p><!-- SPEC -->",
        "handle": "a01d64b9-83d0-47c7-9408-2e88cef09da4",
        "hasOnlyDefaultVariant": false,
        "id": "gid://shopify/Product/7184093282484",
        "images": [
            {
                "id": "gid://shopify/ProductImage/33992882127028",
                "altText": "1Pair Crystal Floral Pattern Butterfly Wings Shaped Sunglasses",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/6ba922ac-a8b5-4747-a710-ae3d78b1bd30-21103-pc.jpg?v=1657273491"
            },
            {
                "id": "gid://shopify/ProductImage/33992882159796",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/6ba922ac-a8b5-4747-a710-ae3d78b1bd30-21103-pc-sec.jpg?v=1657273491"
            },
            {
                "id": "gid://shopify/ProductImage/33992882192564",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/6ba922ac-a8b5-4747-a710-ae3d78b1bd30-21103-0.jpg?v=1657273491"
            },
            {
                "id": "gid://shopify/ProductImage/33992882225332",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/6ba922ac-a8b5-4747-a710-ae3d78b1bd30-21103-1.jpg?v=1657273491"
            },
            {
                "id": "gid://shopify/ProductImage/33992882258100",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/6ba922ac-a8b5-4747-a710-ae3d78b1bd30-21103-2.jpg?v=1657273491"
            }
        ],
        "options": [
            {
                "id": "gid://shopify/ProductOption/9196946325684",
                "name": "Color",
                "position": 1,
                "values": [
                    "style5"
                ]
            },
            {
                "id": "gid://shopify/ProductOption/9196946358452",
                "name": "Size",
                "position": 2,
                "values": [
                    "One Size"
                ]
            }
        ],
        "productType": "",
        "publishedAt": "2022-07-08T09:44:42Z",
        "tags": [
            "Accesorios",
            "Accessoires",
            "Accessories",
            "Acessórios",
            "Acryl",
            "Acrylic",
            "Acrylique",
            "Acrílico",
            "Akryl",
            "Akryyli",
            "Aukahlutir",
            "Aurinkolasit",
            "Chic",
            "Chique",
            "Daglega",
            "Daglig",
            "Daglige",
            "Dagligen",
            "Daily",
            "Des lunettes de soleil",
            "Diariamente",
            "Diario",
            "Elegante",
            "Flottur",
            "Gafas de sol",
            "Lisätarvikkeet",
            "Material_Acrylic",
            "Neu bei Sonnenbrillen",
            "New In Sunglasses",
            "New_Arrivals",
            "Nouveau dans les lunettes de soleil",
            "Novo em óculos de sol",
            "Nuevo en gafas de sol",
            "Nyt i solbriller",
            "Nytt i solbriller",
            "Nytt i solglasögon",
            "Nýtt í sólgleraugu",
            "Occasion_Daily",
            "Oculos de sol",
            "Ombre",
            "Package Include_1Pair Sunglasses",
            "Pattern Type_Ombre",
            "Päivittäin",
            "Quotidien",
            "Schick",
            "Size_One Size",
            "Solbriller",
            "Solglasögon",
            "Sonnenbrille",
            "stil 5",
            "stil5",
            "style5",
            "Style_Chic",
            "stíll5",
            "Sunglasses",
            "Sólgleraugu",
            "tilbehør",
            "Tillbehör",
            "Tresillo",
            "tyyli 5",
            "Tyylikäs",
            "Täglich",
            "Uusi aurinkolasit",
            "Zubehör",
            "Élégant"
        ],
        "templateSuffix": "",
        "title": "1Pair Crystal Floral Pattern Butterfly Wings Shaped Sunglasses",
        "totalInventory": 174,
        "totalVariants": 1,
        "tracksInventory": true,
        "updatedAt": "2024-05-22T23:28:32Z",
        "variants": [
            {
                "availableForSale": true,
                "barcode": "",
                "compareAtPrice": "34.00",
                "createdAt": "2022-07-08T09:44:51Z",
                "displayName": "1Pair Crystal Floral Pattern Butterfly Wings Shaped Sunglasses - style5 / One Size",
                "fulfillmentService": {
                    "id": "gid://shopify/FulfillmentService/manual",
                    "inventoryManagement": false,
                    "productBased": true,
                    "serviceName": "Manual",
                    "type": "MANUAL"
                },
                "id": "gid://shopify/ProductVariant/41640789115060",
                "image": {
                    "id": "gid://shopify/ProductImage/33992882290868",
                    "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/1t6o52603p8M2I4916029r2e5q-21103.jpg?v=1657273491"
                },
                "inventoryItem": {
                    "__typename": "InventoryItem",
                    "id": "gid://shopify/InventoryItem/43737892159668"
                },
                "inventoryManagement": "SHOPIFY",
                "inventoryPolicy": "DENY",
                "inventoryQuantity": 174,
                "position": 1,
                "price": "34.00",
                "product": {
                    "__typename": "Product",
                    "id": "gid://shopify/Product/7184093282484"
                },
                "requiresShipping": true,
                "selectedOptions": [
                    {
                        "__typename": "SelectedOption",
                        "value": "style5"
                    },
                    {
                        "__typename": "SelectedOption",
                        "value": "One Size"
                    }
                ],
                "sku": "YF134-s5-OS-ichic5",
                "taxable": true,
                "title": "style5 / One Size",
                "updatedAt": "2024-04-26T03:05:07Z",
                "weight": 1.1,
                "weightUnit": "GRAMS"
            }
        ],
        "vendor": "ABC Fashion",
        "status": "ACTIVE"
    },
    {
        "availablePublicationCount": 7,
        "createdAt": "2022-07-08T09:44:40Z",
        "descriptionHtml": "<!-- DESC --><p><strong>Style:</strong> Chic<br><strong>Type:</strong> Drop Earrings<br><strong>Material:</strong> Alloy<br><strong>Pattern Type:</strong> Plain<br><strong>Occasion:</strong> Daily<br><strong>Package Include:</strong> 1 Pair of Earrings<br></p><!-- SPEC -->",
        "handle": "9f43606f-0e19-4615-b495-f6417e5c1908",
        "hasOnlyDefaultVariant": false,
        "id": "gid://shopify/Product/7184093249716",
        "images": [
            {
                "id": "gid://shopify/ProductImage/33992881406132",
                "altText": "1Pair Floral & Geo Shaped Drop Earrings",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/140f8fc5-3cd2-41ca-81b6-736568f8e472-99522-pc.jpg?v=1657273480"
            },
            {
                "id": "gid://shopify/ProductImage/33992881438900",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/140f8fc5-3cd2-41ca-81b6-736568f8e472-99522-pc-sec.jpg?v=1657273480"
            },
            {
                "id": "gid://shopify/ProductImage/33992881471668",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/140f8fc5-3cd2-41ca-81b6-736568f8e472-99522-0.jpg?v=1657273481"
            },
            {
                "id": "gid://shopify/ProductImage/33992881504436",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/140f8fc5-3cd2-41ca-81b6-736568f8e472-99522-1.jpg?v=1657273481"
            },
            {
                "id": "gid://shopify/ProductImage/33992881537204",
                "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/140f8fc5-3cd2-41ca-81b6-736568f8e472-99522-2.jpg?v=1657273481"
            }
        ],
        "options": [
            {
                "id": "gid://shopify/ProductOption/9196946260148",
                "name": "Color",
                "position": 1,
                "values": [
                    "gold"
                ]
            },
            {
                "id": "gid://shopify/ProductOption/9196946292916",
                "name": "Size",
                "position": 2,
                "values": [
                    "One Size"
                ]
            }
        ],
        "productType": "",
        "publishedAt": "2022-07-08T09:44:30Z",
        "tags": [
            "Accesorios",
            "Accessoires",
            "Accessories",
            "Acessórios",
            "Aleación",
            "Alliage",
            "Alloy",
            "Almindeligt",
            "Aretes",
            "Aukahlutir",
            "Avião",
            "Boucles d&#39oreilles",
            "Brincos",
            "Brincos pendentes",
            "Chic",
            "Chique",
            "Color_gold",
            "Daglega",
            "Daglig",
            "Daglige",
            "Dagligen",
            "Daily",
            "Des boucles d&#39oreilles",
            "Diariamente",
            "Diario",
            "Drop Earrings",
            "Drop Eyrnalokkar",
            "Drop øreringer",
            "Droppörhängen",
            "Dråbeøreringe",
            "Earrings",
            "Ebene",
            "Elegante",
            "Enkel",
            "Eyrnalokkar",
            "Flottur",
            "gold",
            "guld-",
            "gull",
            "Korvakorut",
            "kulta-",
            "Legering",
            "Legierung",
            "Liga",
            "Lisätarvikkeet",
            "Llanura",
            "Létt",
            "Material_Alloy",
            "Metalliseos",
            "Neu in Ohrringen",
            "New In Earrings",
            "New_Arrivals",
            "Nouveau dans les boucles d&#39oreilles",
            "Novo em brincos",
            "Nuevo en pendientes",
            "Nyt i øreringe",
            "Nytt i örhängen",
            "Nytt i øreringer",
            "Nýtt í eyrnalokkum",
            "Occasion_Daily",
            "Ohrringe",
            "or",
            "oro",
            "ouro",
            "Package Include_1 Pair of Earrings",
            "Pattern Type_Plain",
            "Pendientes de gota",
            "Plain",
            "Plaine",
            "Pudota korvakorut",
            "Päivittäin",
            "Quotidien",
            "Schick",
            "Size_One Size",
            "Style_Chic",
            "Tavallinen",
            "tilbehør",
            "Tillbehör",
            "Tropfenohrringe",
            "Type_Drop Earrings",
            "Tyylikäs",
            "Täglich",
            "Uusi korvakoruissa",
            "Vanlig",
            "Zubehör",
            "Álfelgur",
            "Élégant",
            "Örhängen",
            "Øredobber",
            "Øreringe"
        ],
        "templateSuffix": "",
        "title": "1Pair Floral & Geo Shaped Drop Earrings",
        "totalInventory": 92,
        "totalVariants": 1,
        "tracksInventory": true,
        "updatedAt": "2024-05-22T23:28:32Z",
        "variants": [
            {
                "availableForSale": true,
                "barcode": "",
                "compareAtPrice": "14.00",
                "createdAt": "2022-07-08T09:44:41Z",
                "displayName": "1Pair Floral & Geo Shaped Drop Earrings - gold / One Size",
                "fulfillmentService": {
                    "id": "gid://shopify/FulfillmentService/manual",
                    "inventoryManagement": false,
                    "productBased": true,
                    "serviceName": "Manual",
                    "type": "MANUAL"
                },
                "id": "gid://shopify/ProductVariant/41640789082292",
                "image": {
                    "id": "gid://shopify/ProductImage/33992881635508",
                    "originalSrc": "https://cdn.shopify.com/s/files/1/0617/2485/2404/products/1e6J5X6D3f8t2y4h141i4P6J0t-99522.jpg?v=1657273481"
                },
                "inventoryItem": {
                    "__typename": "InventoryItem",
                    "id": "gid://shopify/InventoryItem/43737892126900"
                },
                "inventoryManagement": "SHOPIFY",
                "inventoryPolicy": "DENY",
                "inventoryQuantity": 92,
                "position": 1,
                "price": "14.00",
                "product": {
                    "__typename": "Product",
                    "id": "gid://shopify/Product/7184093249716"
                },
                "requiresShipping": true,
                "selectedOptions": [
                    {
                        "__typename": "SelectedOption",
                        "value": "gold"
                    },
                    {
                        "__typename": "SelectedOption",
                        "value": "One Size"
                    }
                ],
                "sku": "XR8232-gd-OS-ichic",
                "taxable": true,
                "title": "gold / One Size",
                "updatedAt": "2024-04-16T22:57:40Z",
                "weight": 1.1,
                "weightUnit": "GRAMS"
            }
        ],
        "vendor": "ABC Fashion",
        "status": "ACTIVE"
    }
];